import PropTypes from "prop-types";
import React from "react";

import Item from "./Item";
import Blockquote from "../Blockquote";
import Headline from "../Article/Headline";

const Blog = props => {
  const { posts, theme } = props;

  return (
    <React.Fragment>
      <main className="main">
        <ul>
          <Headline title="Inspire Us" theme={theme} />
          <Blockquote
            title=""
            content="I am passionate about everything in my life--first and foremost, passionate about ideas.
          And that's a dangerous person to be in this society, not just because I'm a woman, 
          but because it's such a fundamentally anti-intellectual, anti-critical thinking society."
            author="Bell Hooks"
            theme={theme}
          />{" "}
          {posts.map(post => {
            const {
              node,
              node: {
                fields: { slug }
              }
            } = post;
            return <Item key={slug} post={node} theme={theme} />;
          })}
        </ul>
      </main>

      {/* --- STYLES --- */}
      <style jsx>{`
        .main {
          padding: 0 ${theme.space.inset.default};
        }

        ul {
          list-style: none;
          margin: 0 auto;
          display: flex;
          flex-flow: row wrap;
        }

        @below tablet {
          ul {
            padding: ${`calc(${theme.space.xxs}) 0 calc(${theme.space.default} * 0.5)`};
            margin-top: ${`calc(${theme.header.height.homepage} * .5)`};
          }
        }

        @above tablet {
          .main {
            padding: 0 ${`0 calc(${theme.space.default} * 1.5)`};
          }
          ul {
            padding: ${`calc(${theme.space.default} * 3) 0 calc(${theme.space.default} * 0.5)`};
            max-width: ${theme.text.maxWidth.tablet};
            margin-top: ${`calc(${theme.header.height.homepage} * 1)`};
          }
        }

        @above desktop {
          .main {
            margin-top: ${`calc(${theme.space.l} * 3)`};
          }
          ul {
            padding: ${`calc(${theme.space.default} * 3) 0 calc(${theme.space.default} * 0.5)`};
            max-width: ${theme.text.maxWidth.desktop};
            margin-top: ${`calc(${theme.header.height.homepage} * 0)`};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Blog.propTypes = {
  posts: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired
};

export default Blog;
